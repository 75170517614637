import React from "react";
import styles from './Layout.module.css';
import  { Header } from './header'
const Layout = ({ children }) => {
    return (
        <div className={styles.Layout}>
            <div className={styles.mastheadContainer}>
                <Header />
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
}

export default Layout;
