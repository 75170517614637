import Layout from "../Layout";
import { AwesomeButton } from "react-awesome-button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import 'react-awesome-button/dist/styles.css';
import "./about.css"

const handleClick = () => {
    window.open('https://discord.gg/4nSVGv8A3g', '_blank');
};

export const About = () => {
    return (
        <Layout>
            <div className="mx-auto max-w-4xl p-4">
                <h1 className="text-2xl font-bold text-center mb-5 mt-12">About Us</h1>
                <p className="text-md text-gray-700 mb-4">
                    We are a diverse team of students from the <strong>University of New South Wales</strong> and <strong>University of Sydney</strong>, specialising in UI/UX, marketing, and software development. Our goal is to make <strong>educational video recordings</strong> more <strong>interactive and supportive</strong>, enhancing student <strong>efficiency</strong> during <strong>self-paced studies</strong>.
                </p>
                <p className="text-md text-gray-700 mb-4">
                    In the future, we plan to integrate <strong>note-taking</strong> into this process, enhancing the learning experience. There is much we can do, and we are always open to <strong>new ideas</strong> and <strong>collaborators</strong>.
                </p>
                <p className="text-md text-gray-700 mb-4">
                    If you are interested in our vision or want to learn more about us, feel free to <strong>contact us</strong> through the link below or join our <strong>Discord</strong> channel to <strong>decide on our next features</strong>. 
                </p>
                <p className="text-md text-gray-700 mb-9">
                    <strong>Your input</strong> will shape our future, whether you wish to <strong>join our team</strong> or become one of our <strong>early adopters</strong>.
                </p>
                <div className="flex justify-center gap-6">
                    <AwesomeButton type="secondary" href="mailto:evan@editeq.net">
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }}/>Contact Us
                    </AwesomeButton>
                    <AwesomeButton type="primary" onPress={handleClick}>
                        <FontAwesomeIcon icon={faDiscord} style={{ marginRight: '8px' }}/>Join Our Discord
                    </AwesomeButton>
                </div>
            </div>
        </Layout>
    );
}