import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { extractYouTubeVideoID, timeInSeconds, getVideoLink, getSegment } from "./helper";
import Layout from "../Layout";
import YouTubePlayer from "./component/YouTubePlayer";
import VideoInfo from "./component/VideoInfo";
import Outline from "./component/Outline";
import Chatbox from "./component/Chatbox";

const Watch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initcurrentTime = params.get('start') ? parseInt(params.get('start'), 10) : 0; // 默认从0秒开始
  // console.log(initcurrentTime);

  const [currentTime, setCurrentTime] = useState(initcurrentTime); // in seconds
  const [seekTo, setSeekTo] = useState(undefined);
  const [seekToTrigger, setSeekToTrigger] = useState(0);
  const [input, setInput] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [segments, setSegments] = useState([]);
  const [currentSegment, setCurrentSegment] = useState(null);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const data = await getSegment(id);
        setSegments(data.segments);
      } catch (err) {
        console.error('Failed to fetch segments:', err);
      }
    };

    if (id) fetchSegments();
  }, [id]);

  useEffect(() => {
    const findCurrentSegment = () => {
      const current = segments.slice().reverse().find(segment => timeInSeconds(segment.start) <= currentTime) || segments[0];
      setCurrentSegment(current);
    };
    if (segments.length > 0) findCurrentSegment();
  }, [currentTime, segments]);

  useEffect(() => {
    const fetchVideoLink = async () => {
      const data = await getVideoLink(id);
      if (data.link) {
        setVideoId(extractYouTubeVideoID(data.link));
      }
    };
    fetchVideoLink();
  }, [id]);

  useEffect(() => {
    // update start
    const params = new URLSearchParams(window.location.search);
    params.set('start', currentTime);
    navigate({ search: params.toString() }, { replace: true });
  }, [currentTime, navigate]);

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  // set video interval to given time (in seconds)
  const handleSeek = (time) => {
    setSeekTo(timeInSeconds(time));
    setSeekToTrigger(seekToTrigger + 1);
  }

  return (
    <Layout>
      <div style={{ display: 'grid', gridTemplateColumns: '65% 35%', height: '100%', width: '100%', gap: '10px' }}>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', gap: '10px' }}>
          <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            <YouTubePlayer videoId={videoId} onTimeUpdate={handleTimeUpdate} seekTo={seekTo} seekToTrigger={seekToTrigger} initialTime={initcurrentTime} />
          </div>
          <VideoInfo _id={id} currentTime={currentTime} setInput={setInput} currentSegment={currentSegment} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '10px', marginRight: '10px' }}>
          <Outline _id={id} onSeek={handleSeek} currentSegment={currentSegment} />
          <Chatbox _id={id} input={input} setInput={setInput} onSeek={handleSeek} currentSegment={currentSegment} />
        </div>
      </div>
    </Layout>
  );
}

export default Watch;
