import React from "react";

const VideoInfo = ({ _id, currentTime, currentSegment }) => {
  return (
    <div id="video-info-container" style={{
        flex: 1,
        display: 'flex', 
        border: "1px solid #d5d9e8",
        flexDirection: 'column',
        padding: "20px",
        borderRadius: "8px",
        marginBottom: '10px',
        overflowY: "auto",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
    }}>
      <div id="video-info" style={{
        height: '100%', 
        width: '100%',
        maxHeight: '0px',
      }}>
        {currentSegment && (
          <>
            <h4 style={{ color: "#1976d2" }}>Segment Summary</h4>
            <p style={{ fontSize: "16px", color: "#555" }}>{currentSegment.summary}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default VideoInfo;

/*
import React, { useState, useEffect } from "react";
import { getSegment, timeInSeconds } from "../helper";

const VideoInfo = ({ _id, currentTime, setInput }) => {
  return (
    <div id="video-info-container" style={{
        flex: 1,
        display: 'flex', 
        flexDirection: 'column',
        backgroundColor: "#ffffff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: '10px',
        overflowY: "auto"
    }}>
      <div id="video-info" style={{
        height: '100%', 
        width: '100%',
        maxHeight: '0px',
      }}>
        {currentSegment && (
          <>
            <h4 style={{ color: "#1976d2" }}>Segment Summary:</h4>
            <p style={{ fontSize: "16px", color: "#555" }}>{currentSegment.summary}</p>
            <h4 style={{ color: "#1976d2" }}>Questions:</h4>
            <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
              {currentSegment.questions.map((question, index) => (
                <li key={index} style={{
                  cursor: 'pointer',
                  marginBottom: "5px",
                  color: "#555",
                  fontSize: "16px",
                  padding: '5px 10px',
                  borderRadius: '10px',
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#ffffff'
                }} 
                onMouseEnter={(e) => e.target.style.backgroundColor = "#e3f2fd"}
                onMouseLeave={(e) => e.target.style.backgroundColor = "#ffffff"}
                onClick={() => handleQuestionClick(question)}
                >
                  {question}
                </li>
              ))}
            </ul>
          </>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
}

export default VideoInfo;
*/
