import React, { useState, useRef, useEffect } from 'react';
import { getAnswerInVideo, secondsToTime } from '../helper';
import ReactMarkdown from 'react-markdown';
import styles from './Chatbox.module.css'; // Importing CSS module
import { HiOutlineLightBulb } from "react-icons/hi";
import { quantum } from 'ldrs';

quantum.register();

const Chatbox = ({ _id, input, setInput, onSeek, currentSegment }) => {
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false); // State to track response generation
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [config, setConfig] = useState(() => {
    // Getting the stored value from localStorage during initial state setup
    return localStorage.getItem('config') || 'moderate';
  });

  const textAreaRef = useRef(null);
  const chatHistoryRef = useRef(null); // Ref for chat history div

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsTextareaFocused(false);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current);
    }
  }, [input]);

  useEffect(() => {
    localStorage.setItem('config', config);
  }, [config]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextAreaHeight(e.target);
  };

  const adjustTextAreaHeight = (textArea) => {
    textArea.style.height = 'auto'; // Reset height to re-calculate
    if (textArea.scrollHeight < 100) { // 100px approximately fits five lines
      textArea.style.height = `${textArea.scrollHeight}px`;
    } else {
      textArea.style.height = '100px'; // Max height for 5 lines
    }
  };

  const modifyLinkText = (text) => {
    const regex = /\[Video\]\((http:\/\/localhost:3000\/watch\/([^?]+)\?start=(\d+))\)/g;
    return text.replace(regex, (match, url, id, start) => {
      if (id === _id) {
        return ` [${secondsToTime(start)}](${url})`;
      }
      return match;
    });
  };

  const sendMessage = async (input) => {
    if (!input.trim() || isGenerating) return;
    setIsGenerating(true); // Indicate that a response is being generated
    const userMessage = { author: 'user', text: input.trim() };
    setMessages(messages => [...messages, userMessage]);
    setInput('');

    // Prepare chat history
    let history = [];
    if (messages.length > 0) {
      history = messages.map((msg) => ({
        role: msg.author.toLowerCase(),
        content: msg.text
      }));
    }

    // Reset textarea height
    textAreaRef.current.style.height = 'auto';

    // Add a temporary message indicating the system is generating an answer
    setMessages(messages => [...messages, { author: 'assistant', text: '', isGenerating: true }]);

    const response = await getAnswerInVideo(history, input.trim(), _id, config);
    if (response && response.answer) {
      // Replace the temporary message with the actual answer
      const modifiedAnswer = modifyLinkText(response.answer);
      setMessages(messages => [
        ...messages.slice(0, -1),
        { author: 'assistant', text: modifiedAnswer, markdown: true, isNew: true }
      ]);
    } else {
      // Handle errors or no response cases
      setMessages(messages => [
        ...messages.slice(0, -1),
        { author: 'assistant', text: 'Unable to retrieve an answer, please try again later.', isNew: true }
      ]);
    }
    setIsGenerating(false); // Reset the generating state
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const handleQuestionClick = (question) => {
    console.log(question);
    setInput(question);
  };

  const handleQuestionClickAndSubmit = (question) => {
    sendMessage(question);
  };

  const codeBlockStyle = {
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px',
    borderRadius: '5px',
    overflowX: 'auto',
  };

  const handleLinkClick = (event, href) => {
    const url = new URL(href, window.location.origin);
    const params = new URLSearchParams(url.search);
    if (href.includes(_id) && params.has('start')) {
      event.preventDefault();
      onSeek(params.get('start'));
    }
  };

  useEffect(() => {
    const timeoutIds = [];
    messages.forEach((msg, index) => {
      if (msg.isNew) {
        const timeoutId = setTimeout(() => {
          setMessages((msgs) =>
            msgs.map((m, i) =>
              i === index ? { ...m, isNew: false } : m
            )
          );
        }, 500);
        timeoutIds.push(timeoutId);
      }
    });
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [messages]);

  return (
    <div className={styles.chatArea}>
      <div className={styles.chatComponentContainer}>
        <div className={styles.chatHistory} ref={chatHistoryRef}>
          {messages.length === 0 ? (
            <div style={{ textAlign: 'center', marginTop: '20px', color: '#9e9e9e' }}>
              {currentSegment && (
                <>
                  <div className="relative inline-block mb-2">
                    <HiOutlineLightBulb size={30}/>
                  </div>
                  <h4 className={styles.headingStyle}>You might want to ask ...</h4>
                  <ul style={{ listStyleType: "none" }}>
                    {currentSegment.questions.slice(0, 3).map((question, index) => (
                      <li key={index} className={styles.questionStyle} 
                        onClick={() => handleQuestionClickAndSubmit(question)}
                      >
                        {question}
                      </li>
                    ))}
                  </ul>

                  <div className="flex justify-center mt-6 mb-4">
        <div className="inline-flex border border-gray-300 rounded-md" role="group">
          <button 
            type="button" 
            className={`px-6 py-2 text-sm font-medium text-center rounded-l-lg ${config === 'depth' ? 'text-gray-900' : 'text-gray-400'}`}
            onClick={() => setConfig('depth')}
          >
            Depth
          </button>
          <button 
            type="button" 
            className={`px-6 py-2 text-sm font-medium text-center ${config === 'moderate' ? 'text-gray-900' : 'text-gray-400'}`}
            onClick={() => setConfig('moderate')}
          >
            Moderate
          </button>
          <button 
            type="button" 
            className={`px-6 py-2 text-sm font-medium text-center rounded-r-lg ${config === 'broad' ? 'text-gray-900' : 'text-gray-400'}`}
            onClick={() => setConfig('broad')}
          >
            Broad
          </button>
        </div>
      </div>

                </>
              )}
            </div>
          ) : (
            <>
              {messages.map((msg, index) => (
                <div key={index} className={`${msg.author === 'user' ? styles.messageUser : styles.messageBot}`}>
                  {msg.isGenerating ? (
                    <div className={styles.loadingIcon}>
                      <l-quantum
                        size="30"
                        speed="1.75" 
                        color="black"
                      ></l-quantum>
                    </div>
                  ) : msg.markdown ? (
                    <div className={`${styles.newMessage}`}>
                      <ReactMarkdown
                        components={{
                          a: ({node, ...props}) => (
                            <a {...props} className={styles.linkStyle} onClick={(event) => handleLinkClick(event, props.href)}>
                              {props.children}
                            </a>
                          ),
                          pre: ({node, children, ...props}) => {
                            const hasCodeTag = React.Children.toArray(children).some(child => child.type === 'code');
                            if (hasCodeTag) {
                              // return <pre style={styles.codeBlockStyle}>{children}</pre>;
                              return <pre style={codeBlockStyle}>{children}</pre>;
                            }
                            return <pre>{children}</pre>;
                          }
                        }}
                      >
                        {msg.text}
                      </ReactMarkdown>
                    </div>
                    
                  ) : (
                    <p>{msg.text}</p>
                  )}
                </div>
              ))}
              <div style={{ height: '18%' }}></div> {/* Spacer div for white space */}
            </>
          )}
        </div>
        
        <div style={{ display: 'flex', marginTop: 'auto', marginBottom: '10px', position: 'relative' }}>
          {isTextareaFocused &&  !input && messages.length !== 0 && currentSegment && !isGenerating && (
            <div className={styles.questionPrompt}>
              <ul className={styles.questionList}>
                {currentSegment.questions.slice(0, 3).map((question, index) => {
                  const reverseIndex = currentSegment.questions.slice(0, 3).length - 1 - index;
                  return (
                    <li 
                      key={index} 
                      onMouseDown={() => handleQuestionClick(question)} 
                      className={styles.questionStyle} 
                      style={{ '--animation-delay': `${reverseIndex * 0.1}s` }}
                    >
                      {question}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <textarea
            ref={textAreaRef}
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onFocus={handleTextareaFocus}
            onBlur={handleTextareaBlur}
            className={styles.textArea}
            placeholder="Type your question here"
            rows={1}
          />
          <button
            onClick={() => sendMessage(input)} 
            className={styles.sendButton}
            disabled={!input.trim() || isGenerating} // Button is disabled if input is empty or response is generating
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
