import React, { useState, useEffect, useRef } from "react";
import { getOutline } from "../helper";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const Outline = ({ _id, onSeek, currentSegment }) => {
  const [outline, setOutline] = useState(null);
  const [currentSegmentTime, setCurrentSegmentTime] = useState(null);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const segmentRefs = useRef({}); // Stores refs for all segments

  useEffect(() => {
    if (currentSegment && currentSegment.start) {
      setCurrentSegmentTime(currentSegment.start);
    }
  }, [currentSegment]);

  useEffect(() => {
    const fetchOutline = async () => {
      try {
        const data = await getOutline(_id);
        setOutline(data.timestamps);
      } catch (err) {
        console.error('Failed to fetch outline:', err);
        setError('Failed to fetch outline. Please try again later.');
      }
    };

    if (_id) {
      fetchOutline();
    }
  }, [_id]);

  useEffect(() => {
    if (currentSegmentTime && segmentRefs.current[currentSegmentTime]) {
      segmentRefs.current[currentSegmentTime].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [currentSegmentTime, outline]);

  return (
    <div id="outline" style={{
      padding: "20px",
      border: "1px solid #d5d9e8",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
    }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
           onClick={() => setCollapsed(!collapsed)}>
        <h2 style={{ color: "#1976d2", margin: 0, userSelect: "none" }}>Timeline</h2>
        <div>
          {collapsed ? <FiChevronDown color="#1976d2" size="24px" /> : <FiChevronUp color="#1976d2" size="24px" />}
        </div>
      </div>
      {!collapsed && (
        <div style={{
          maxHeight: "20vh",
          marginTop: "10px",
          overflowY: "scroll"
        }}>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {outline ? (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {outline.map((item, index) => (
                <li key={index} ref={el => segmentRefs.current[item.timestamp] = el} style={{
                  marginBottom: "10px",
                  color: item.timestamp === currentSegmentTime ? "#9c27b0" : "#333",
                  fontSize: "16px"
                }}>
                  <span style={{
                    color: "#1976d2",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }} onClick={(e) => {
                      e.stopPropagation();
                      onSeek(item.timestamp);
                  }}>
                    {item.timestamp}
                  </span>
                  {": "}
                  <span onClick={(e) => {
                      e.stopPropagation();
                      onSeek(item.timestamp);
                  }} style={{
                    cursor: "pointer",
                    color: item.timestamp === currentSegmentTime ? "#9c27b0" : "#555"
                  }}>
                    {item.content}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>Loading outline...</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Outline;
