import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useNavigate } from 'react-router-dom';
import { getVideoList } from "../watch-page/helper";
import styles from './Home.module.css';
import { hatch } from 'ldrs'

hatch.register()

const YOUTUBE_API_KEY = "AIzaSyB7-9M_aHuIvq865mSW_axBtnkdY_P3pUU"

const fetchYouTubeDetails = async (id, videoId) => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.items.length > 0) {
    const snippet = data.items[0].snippet;
    return {
      _id: id,
      thumbnailUrl: snippet.thumbnails.high.url,
      title: snippet.title,
      author: snippet.channelTitle,
      publishDate: snippet.publishedAt // Fetch the publish date
    };
  }
  throw new Error('Video not found');
};

const extractYouTubeID = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get('v');
};

export const Home = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchVideoList = async () => {
      setIsLoading(true);
      try {
        const list = await getVideoList();
        const videoDetails = await Promise.all(list.videos.map(async video => {
          return fetchYouTubeDetails(video._id, extractYouTubeID(video.link));
        }));
        // Sort videos by publish date
        videoDetails.sort((a, b) => new Date(a.publishDate) - new Date(b.publishDate));
        console.log(videoDetails);
        setVideos(videoDetails);
      } catch (err) {
        console.error('Failed to fetch video data:', err);
      } finally {
        setIsLoading(false); // End loading
      }
    };
    fetchVideoList();
  }, []);

  console.log(videos);
  return (
    <Layout>
      {isLoading ? (
        <div className={styles.loader}>
          <l-hatch size="60" stroke="6" speed="4.4" color="black"></l-hatch>
        </div>
      ) : (
        <div className={styles.videoList}>
          {videos.map((video, index) => (
            <div key={index} className={styles.videoCard} onClick={() => navigate(`/watch/${video._id}`)}>
              <img src={video.thumbnailUrl} alt="Video Thumbnail" className={styles.videoThumbnail} />
              <div className={styles.videoInfo}>
                <h3 className={styles.videoTitle}>{video.title}</h3>
                <p className={styles.videoAuthor}>by {video.author}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
}
