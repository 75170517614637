import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Watch from './pages/watch-page/Watch';
import { Home } from './pages/home-page/Home';
import Test from './pages/test-page/Test'
import { About } from './pages/about-page/about'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/Watch/:id" element={<Watch />} />
          <Route path="/about" element={<About/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
