import React from "react";
import { Link, useLocation } from 'react-router-dom';
import ediLogo from '../edi-logo-white.png';
import styles from './header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

export const Header = () => {
    const location = useLocation();

    return (
        <div className={styles.header}>
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 w-full">
                <nav className="flex items-center justify-between h-full w-full">
                    <div className="flex-shrink-0">
                        <Link to="/" title="Home" className="flex items-center h-full">
                            <img className="w-auto h-8 lg:h-10" src={ediLogo} alt="Edi" />
                        </Link>
                    </div>

                    <button type="button" className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100">
                        <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                        </svg>
                        <svg className="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
                        <Link to="/" title="" className={location.pathname === "/" ? `${styles.link} ${styles.active}` : styles.link} > Dashboard </Link>

                        <Link to="/about" title="" className={location.pathname === "/about" ? `${styles.link} ${styles.active}` : styles.link}> About Us </Link>

                        <a href="mailto:evan@editeq.net" title="Send us an email" className={styles.link}>
                            Contact Us
                        </a>

                    </div>

                    <a href="https://discord.gg/4nSVGv8A3g" title="" rel="noopener noreferrer" className="items-center justify-center hidden px-3 py-2 ml-10 text-xs font-bold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700" role="button" target="_blank"> <FontAwesomeIcon icon={faDiscord} style={{ marginRight: '8px' }}/> Join Our Discord </a>
                </nav>

                <nav className="pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden">
                    <div className="flow-root">
                        <div className="flex flex-col px-6 -my-2 space-y-1">
                            <Link to="/" title="" className="text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Dashboard </Link>

                            <Link to="/about" title="" className="text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> About Us </Link>

                            <a href="mailto:evan@editeq.net" title="" className="text-base font-medium text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> Contact Us </a>

                            <a href="https://discord.gg/4nSVGv8A3g" title="" rel="noopener noreferrer" className="items-center justify-center hidden px-3 py-2 ml-10 text-sm font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700" role="button" target="_blank"> Join Our Discord </a>
                        </div>
                    </div>

                    <a href="https://discord.gg/4nSVGv8A3g" rel="noopener noreferrer" className="items-center justify-center hidden px-3 py-2 ml-10 text-xs font-bold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md lg:inline-flex hover:bg-blue-700 focus:bg-blue-700" role="button" target="_blank">Join Our Discord</a>
                </nav>
            </div>
        </div>
    );
};