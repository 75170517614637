/**
 * Extracts the video ID from a YouTube URL.
 * This function supports both the full YouTube URL and the shortened youtu.be format.
 *
 * @param {string} url - The YouTube video URL from which the video ID needs to be extracted.
 * @returns {string|null} The extracted video ID if found, otherwise returns null.
 */
export const extractYouTubeVideoID = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}


/**
 * Performs authenticated HTTP requests to a specified path.
 *
 * @param {string} method - The HTTP method.
 * @param {string} path - The URL path to send the request to.
 * @param {Object|null} body - The body of the request, for non-GET requests.
 * @param {string|null} token - The authentication token, if applicable.
 * @returns {Promise<any>} The data received from the request if successful, or null in case of error.
 */
const authFetch = async (method, path, body = null, token = null) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const header = {
    'Content-Type': 'application/json',
  };
  if (token) header.Authorization = token;

  const fetchOptions = {
    method,
    headers: header
  };

  if (body && method !== 'GET') fetchOptions.body = JSON.stringify(body);

  try {
    const response = await fetch(`${apiUrl}/${path}`, fetchOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch error: ', error);
    alert('An error occurred while fetching data.');
    return null;
  }
};

/**
 * Fetches video timestamps and their associated content descriptions from the backend.
 * This function makes a POST request to retrieve the timestamps outlining the video's content sections.
 *
 * @param {string} videoId - The unique identifier for the video for which to retrieve the outline.
 * @returns {Promise<Object>} A promise that resolves to an object containing an array of timestamps.
 * Each timestamp object includes a 'timestamp' string indicating the time in the video and
 * 'content' string describing what is covered at that time.
 * @throws {Error} Logs and rethrows an error if the fetch operation fails, allowing
 * error handling in the calling context.
 */
export const getOutline = async (videoId) => {
  const path = `video/timestamps/${videoId}`;
  try {
    return await authFetch('POST', path);
  } catch (error) {
    console.error('Error fetching video data:', error);
    throw error;
  }
};

/**
 * Fetches video segment information for a specified video ID.
 * This function makes a POST request to retrieve segments that include start times,
 * summaries, and a list of questions related to each video segment.
 *
 * @param {string} videoId - The unique identifier for the video.
 * @returns {Promise<Object>} A promise that resolves to an object containing an array of video segments.
 * Each segment includes the start time, summary, and related questions.
 * @throws {Error} If the fetch operation fails, logs the error and rethrows it.
 */
export const getSegment = async (videoId) => {
  const path = `video/segments/${videoId}`;
  try {
    return await authFetch('POST', path);
  } catch (error) {
    console.error('Error fetching video data:', error);
    throw error;
  }
}

/**
 * Converts a time string in "HH:mm:ss" format to seconds.
 * If the input doesn't contain exactly two colons, return itself
 *
 * @param {string} time - Time in "HH:mm:ss" format.
 * @returns {number} Total seconds corresponding to the input time.
 */
export const timeInSeconds = (time) => {
  if ((time.match(/:/g) || []).length !== 2) return time;
  // eg: turn time (00:15:12) to seconds (15*60+12)
  const parts = time.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);
  return hours * 3600 + minutes * 60 + seconds;
}

/**
 * Converts a total number of seconds to a time string in "HH:mm:ss" format.
 * If the input is a string, it attempts to convert it to an integer.
 * Ensures two digits for hours, minutes, and seconds by padding with zeros if necessary.
 *
 * @param {number|string} totalSeconds - Total seconds to be converted, can be a string or number.
 * @returns {string} Time in "HH:mm:ss" format.
 */
export const secondsToTime = (totalSeconds) => {
  // Convert to number if input is a string
  if (typeof totalSeconds === 'string') {
    totalSeconds = parseInt(totalSeconds, 10);
    if (isNaN(totalSeconds)) {
      return 'Invalid input';  // Return error message if conversion fails
    }
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Padding the hours, minutes, and seconds with leading zeros if they are less than 10
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

/**
 * Sends a query to the backend to retrieve an answer using the `authFetch` function.
 *
 * @param {string} query - The user's query to send.
 * @param {string} token - Optional authentication token if needed.
 * @returns {Promise<any>} The answer from the backend if successful, or null in case of error.
 */
export const getAnswer = async (query, token = null) => {
  const path = 'generate-answer/';
  const body = {
    query: query
  };
  return await authFetch('POST', path, body, token);
}

/**
 * Sends a query to the backend to get question in this current video
 *
 * @param {list} history - The user's chat history.
 * @param {string} query - The user's query to send.
 * @param {string} token - Optional authentication token if needed.
 * @returns {Promise<any>} The answer from the backend if successful, or null in case of error.
 */
export const getAnswerInVideo = async (history, query, videoId, config, token = null) => {
  const path = `generate-answer-video/${videoId}`;
  const body = {
    history: history,
    query: query,
    config: config
  };
  return await authFetch('POST', path, body, token);
}

export const getVideoList = async (token = null) => {
  const path = 'video/list/';
  return await authFetch('GET', path, token);
}

export const getVideoLink = async (videoId) => {
  const path = `video/link/${videoId}`;
  return await authFetch('GET', path);
}
