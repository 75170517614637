import React, { useEffect, useRef, useState } from 'react';

const YouTubePlayer = ({ videoId, onTimeUpdate, seekTo, seekToTrigger, initialTime }) => {
  const playerRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        initializePlayer();
      }

      window.onYouTubeIframeAPIReady = () => {
        initializePlayer();
      };
    };

    const initializePlayer = () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      playerRef.current = new window.YT.Player('player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          'rel': 0,
          'autoplay': 0,
          'controls': 1
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    };

    if (videoId) {
      loadYouTubeAPI();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  useEffect(() => {
    if (seekTo !== undefined && playerRef.current) {
      playerRef.current.seekTo(seekTo, true);
    }
  }, [seekTo, seekToTrigger]);

  function onPlayerReady(event) {
    // update current video interval
    const newIntervalId = setInterval(() => {
      const currentTime = playerRef.current.getCurrentTime();
      onTimeUpdate(currentTime);
    }, 1000); // update every second
    setIntervalId(newIntervalId);
    
    // Ensure the video starts at the specified initial time
    playerRef.current.loadVideoById({
      videoId: videoId,
      startSeconds: initialTime
    });
  }

  function onPlayerStateChange(event) {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // logic in playing
    }
  }

  return (
    <div id="player" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></div>
  );
};

export default YouTubePlayer;
